/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.






jQuery(document).ready(function($) {
	
	// MAIN MENU DROP DOWNS
	$(".nav-primary ul.nav > li.menu-item-has-children").mouseenter(function() {
		console.log('hovered');
		$(this).children('.sub-menu').stop().slideDown('fast');
	});
	
	
	$(".nav-primary ul.nav > li.menu-item-has-children").mouseleave(function() {
		$(this).children('.sub-menu').stop().slideUp('fast');
	});
	
	
	
	// MOBILE NAV
	
	$('.show-mobile-nav').click(function() {
		$('body').toggleClass('mobile-nav-open');
		$('.mobile-nav').toggleClass('show');
	});
	
	
	$('.mobile-nav ul.menu>li').click(function() {
		
		var clicked = $(this);
		var clickedSelf = clicked.hasClass('open');
			
		// Close menus
		$('ul.menu>li').removeClass('open');
		//$('.sub-menu').slideUp();
		
		if (!clickedSelf) {
			clicked.addClass('open');
			clicked.children('.sub-menu').slideDown();
		} else {
			clicked.children('.sub-menu').slideUp();
		}
		
	});
	
	
	$('.mobile-nav ul.menu>li ul').click(function(e) {
		//e.stopPropagation();
	});
       
	
	$('.pull-tab').click(function() {
		$('aside.sidebar .course-filters').toggleClass('open');
	});
	
	
	// Homepage tiles colorbox links
	$(".tile.youtube").colorbox({
		iframe:true, 
		width: '95%',
		height: '95%',
		maxWidth: '1920px',
		maxHeight: '1080px',
		
	});
	$(".tile.vimeo").colorbox({iframe:true, innerWidth:640, innerHeight:390});
	
	
	//$(".label-text").click(function() {
	$(".checkbox-label").mouseup(function() {
		
		console.log('.checkbox-label clicked');
		
		if ( $(this).hasClass("checked") ) {
			$(this).removeClass("checked");
			$(this).children('.checkbox').prop( "checked", false );
			console.log('checked input');
		} else {
			$(this).addClass("checked");
			$(this).children('.checkbox').prop( "checked", false );
		}
		
		
		if ( $('.checkbox-label.checked').length > 0) {
			// If any boxes are checked, add checked class to container div for highlighting
			$('.activities-selector').addClass('checked');	
			console.log('checked ' + $('.checkbox-label.checked').length);
		} else {
			$('.activities-selector').removeClass('checked');
			console.log('unchecked '+ $('.checkbox-label.checked').length);
		}
		
	});
	
	
	// Show Form Button
	
	if ($('.form_popup').length > 0) {
		$(".form_popup").colorbox({inline:true, width:"50%", height:"80%"});
	}
	
	$(".trigger").click(function() {
		console.log('clicked');
		$(this).siblings('.expander').slideToggle();
		event.preventDefault();
	});
	
	$(".show-enroll-form").click(function() {
		var courseID = $(this).data("course-id");
		var programID = $(this).data("program-tag");
		
		$(".enroll-form-container").slideDown(function() {
			$('html, body').animate({
		        scrollTop: $(".enroll-form-container").offset().top
		    }, 2000);
		    
		    $("#enroll-form .course_id input").val(courseID);
		});
	});
	
	
	// Staff Member Page
	
	$('.staff-tile').click(function() {
		
		var staff = $(this).parent();
		var bio = $(this).siblings('.bio');
		var staffHeight = staff.height();
		var bioHeight = bio.height();
		
		var newHeight = staffHeight + bioHeight + 85;
		
		console.log('staffHeight: ' + staffHeight);
		console.log('bioHeight: ' + bioHeight);
		
		var clickedSelf = staff.hasClass('active');
		
			
		// Close bio
		$('.bio').css("visibility", "hidden");
		$('.staff-member.active').css("height", '');
		$('.staff-member').removeClass('active');
		
		if (!clickedSelf) {
			
			console.log('not self');
			
			staff.css("height", newHeight);
			staff.addClass('active');
			bio.css("visibility", "visible");
		} else {
			
			console.log('self');
		}
	});
	
	
	// Cache the Window object
	var $window = $(window);
	
	/////////////////////////
	// Landing Pages - Parallax Slider
                
	$('div[data-type="background"]').each(function(){
		var $bgobj = $(this); // assigning the object
		
		var $slider = $bgobj + ' .slider';
		        
		$(window).scroll(function() {
			        
			// Scroll the background at var speed
			// the yPos is a negative value because we're scrolling it UP!								
			var yPos = -($window.scrollTop() / $bgobj.data('speed')); 
			
			// Put together our final background position
			var coords = '50% '+ yPos + 'px';
			
			var marginTopPx = yPos + 'px';
			
			// Move the background
			$bgobj.css({ backgroundPosition: coords });
			$('.header-video').css({ marginTop: marginTopPx });
			
			//console.log(yPos);
			
		}); // window scroll Ends
		
	}); 
	
	/////////////////////////
	// Home Page - Parallax Canoe Kid
                
	$('.panel-row-style-canoe-kid').each(function(){
		
		if ($(window).width() > 600) {
			
			var $bgobj = $(this); // assigning the object
			var $speed = 0.95;
			        
			$(window).scroll(function() {
				
				
				var $WindowScrollTop = $(this).scrollTop();
				var $bgobjOffset = $bgobj.offset();
				var $bgobjTop = $bgobjOffset.top;
				        
				// Scroll the background at var speed
				// the yPos is a negative value because we're scrolling it UP!								
				var yPos = -(($WindowScrollTop / $speed) - $bgobjTop); 
				
				// Put together our final background position
				var coords = '50% '+ yPos + 'px';
				
				// Move the background
				$bgobj.css({ backgroundPosition: coords });
				
				console.log( 'yPos:' + yPos + ' $WindowScrollTop:' + $WindowScrollTop + ' $bgobjTop:' + $bgobjTop );
				
			}); // window scroll Ends
			
		}
		
	}); 
	
	/////////////////////////
	// Sidebar Course Details Buttons
                
	$('.sidebar .top-bar').each(function(){
		
		        
		$(window).scroll(function() {
			        
			// Scroll the background at var speed
			// the yPos is a negative value because we're scrolling it UP!								
			var yPos = -($window.scrollTop()); 
			
			if (yPos < -420) {
				$('.sidebar .top-bar').addClass('scrolled');
			} else {
				$('.sidebar .top-bar').removeClass('scrolled');
			}
			console.log(yPos);
			
		}); // window scroll Ends
		
	}); 
	
	
	
	// FAQ Expanders
	
	$('.faq-list .question').click(function(e) {
		$(this).siblings('.answer').slideToggle('fast');
		$(this).toggleClass('open');
	});
	
	
});



// Handle Responsive Colrobox
var cboxOptions = {
  width: '95%',
  height: '95%',
  maxWidth: '960px',
  maxHeight: '960px',
}

$('.cbox-link').colorbox(cboxOptions);

$(window).resize(function(){
    $.colorbox.resize({
      width: window.innerWidth > parseInt(cboxOptions.maxWidth) ? cboxOptions.maxWidth : cboxOptions.width,
      height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
    });
});
